import { ReactSVG } from "react-svg";

export const Image = ({ src, color = "#fff", height, width }) => {
    return (
        <ReactSVG
            src={src}
            $filled={true}
            beforeInjection={(svg) => {
                svg.setAttribute("style", `height: ${height}px; width: ${width}px;`);
                svg.querySelectorAll("path").forEach((path) => {
                    path.setAttribute("fill", color);
                });
            }}
            // fill={color ?? "#fff"}
            height={height ?? 25}
            width={width ?? 25}
        />
    )
}