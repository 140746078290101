import React, { useCallback, useState } from "react";
import wl_logo from "../static/images/WL_LOGO.svg";
import wl_logo_short from "../static/images/logos/wl_logo_short_coloured.svg";
import wl_logo_long from "../static/images/WL_LOGO.svg";
import "../sass/layout/_footer2.scss";
import "../sass/main.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";
import checkIcon from "../static/images/icons/checkMark.svg";
import instaIcon from "../static/images/icons/instagram.svg";
import linkedIcon from "../static/images/icons/linkedIn.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Image } from "../components/Image.js";

export const Footer = () => {
  const { width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [renderTick, setRenderTick] = useState(false);
  const navigate = useNavigate();

  const onClick = useCallback(async () => {
    if (email) {
      const response = await axios
        .post("https://wander-server.vercel.app/api/subscribe", { email })
        .catch((e) => console.log("There was an error while subscribing..", e));

      if (response.data.success) {
        setRenderTick(true);
        setTimeout(() => {
          setRenderTick(false);
        }, 10000);
      }
    }
  }, [email]);

  const onIconClick = useCallback(() => {
    navigate("/");
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-left-up">
          <div className="footer-section">
            <div className="footer-section-label">Quick Links</div>
            <div className="footer-section-links">
              <a href="/">Home</a>
              <a href="/about">About Us</a>
              <a href="/contact">Contact Us</a>
              <a href="/book">Book Now</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>

          <div
            className="footer-section"
          // style={{ width: width < 600 ? "50%" : "100%" }}
          >
            <div className="footer-section-label">Destinations</div>
            <div className="footer-section-links">
              <a href="/:India">India</a>
              <a href="/:Bhutan">Bhutan</a>
              <a href="/:SriLanka">Sri Lanka</a>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-section-label">Travel Experience</div>
            <div className="footer-section-links">
              <ul>
                <div>Luxury journeys and empowering adventures</div>
                <div>Exclusively for women</div>
              </ul>

              <div style={{ marginTop: width > 600 ? "1rem" : "3rem" }}>
                <div
                  style={{ alignSelf: "flex-end" }}
                  className="footer-section-label"
                >
                  Terms and Conditions apply
                </div>
                <div className="footer-section-links">
                  <div>Sailing Seascapes {width < 600 && <br />} PTE. LTD.</div>
                  <div>TA03724</div>
                </div>

                <div className="footer-section-links">
                  <a href="mailto:info@wanderluxe.sg">info@wanderluxe.sg</a>
                </div>

              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-label">Follow Us</div>
            <div className="footer-section-links" style={{ flexDirection: "row", justifyContent: width > 900 ? "space-between" : "flex-start" }}>
              {/* <img alt="instagram" src={instaIcon} height={25} width={25} /> */}
              <a href="https://www.instagram.com/wanderluxe_sg/profilecard/?igsh=a21ldXNvd20yZzk3">
                <Image src={instaIcon} height={25} width={25} color="red" />
              </a>
              <a href="https://www.linkedin.com/company/wanderluxe-sg/">
                <Image alt="linkedIn" src={linkedIcon} height={25} width={25} />
              </a>
            </div>
          </div>
        </div>
        <image className="footer-left-down" onClick={onIconClick}>
          <object data={wl_logo} type="image/svg+xml" onClick={onIconClick}>
            WanderLuxe
          </object>
        </image>
      </div>

      <div className="footer-right">
        <div className="footer-section">
          <div className="footer-right-form">
            <label>SUBSCRIBE TO OUR NEWSLETTER :</label>
            <div className="footer-right-form-content">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                name="email"
                placeholder="someone@gmail.com"
              />
              <button
                disabled={renderTick}
                onClick={onClick}
                className="footer-button"
              >
                {renderTick ? (
                  <img
                    src={checkIcon}
                    height={20}
                    width={25}
                    alt="Subscribe to newsletter done!!"
                  />
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className="footer-section logo"
          style={{ justifyContent: "space-between" }}
        >
          <img
            style={{ width: width > 900 ? "50%" : "100%", alignSelf: width > 600 && "flex-end" }}
            alt="wanderluxe logo"
            src={width > 600 ? wl_logo_short : wl_logo_long}
            typeof="image/svg+xml"
            onClick={onIconClick} />

          <div className="footer-copyright">
            <div>© Wanderluxe SG 2024</div>
            <div>Designed by Black Prism Designs</div>
          </div>
        </div>
      </div>
    </div>
  );
};
